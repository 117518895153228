<template>
  <div class="landingView-container">
    <div class="search-component">
      <search-component @clickedSearch="findShipmentWidget" />
    </div>
    <div class="illustration-container">
      <img src="../assets/images/person-mobile.svg" alt="PostNord" />
      <div class="background-fill" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { watch } from "vue"
import SearchComponent from '../components/SearchComponent.vue';
import { RouteLocation, useRoute, useRouter } from "vue-router"
import { onMounted, ref } from 'vue'
import { decodeQueryString } from '../services/urlQueryParamservice'

const { params } = useRoute()

const props = defineProps<{
  component?: string
}>()

const route = useRoute()
const router = useRouter()
const shipmentId = ref("")

onMounted(() => {
  const queryId = route.query.id || route.query.ID
  shipmentId.value = decodeQueryString(queryId).shipmentId
  console.log({
    message: 'LandingView',
    queryId,
    shipmentId: shipmentId.value,
    queryKey: route.query?.key,
    custNo: route.query?.custNo
  })
  if (route.query.id || route.query.ID) {
    router.replace({
      path: `/${params.lang}/tracking`,
      query: {
        id: shipmentId.value,
        token: route.query.p as string
      }
    })
  } else if (route.query.key) {
    router.replace({
      path: `/${params.lang}/verification`,
      query: {
        key: route.query.key,
        token: route.query.token,
        lang: route.query.lang,
        country: route.query.country,
        action: route.query.action
      }
    })
  } else if (route.query.custNo) {
    router.replace({
      name: 'ReferenceView',
      params: {
        lang: params.lang,
        custNo: route.query.custNo,
        refId: route.query.refId
      },
    })
  }
})

const findShipmentWidget = (shipmentId: string) => {
  if (!shipmentId) return;
  router.push({
    path: `/${params.lang}/tracking`,
    query: {
      id: shipmentId,
    }
  });
}
</script>

<style lang="scss" scoped>
.landingView-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  overflow-x: hidden;

  .search-component {
    max-width: 700px;
    align-self: center;
    justify-self: end;
    padding-left: 2em;
  }

  .illustration-container {
    display: flex;
    position: relative;
    flex-shrink: 0;
    justify-self: center;

    .background-fill {
      position: absolute;
      align-self: center;
      top: 0;
      left: 99%;
      background: $blue700;
      height: 100%;
      width: 50vw;
    }
  }
}

@media only screen and (max-width: 880px) {
  .landingView-container {
    display: flex;
    flex-flow: column nowrap;
    overflow-x: visible;

    .search-component {
      margin-top: 0em;
      margin-left: 0em;
      margin-right: 0em;
      margin-bottom: 0em;
      padding-left: 0em;
    }

    .illustration-container {
      align-self: flex-end;
      margin-top: 2em;

      img {
        width: 33rem;
      }

      .background-fill {
        display: none;
      }
    }
  }
}
</style>
