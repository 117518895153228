import { translationInstance } from '@/localization/i18n'
import { setNormalizedLocale } from '@public-tracking/shared/services/localization'

import { createRouter, createWebHistory, type RouteRecordRaw } from 'vue-router'

import { updatePreviousSearches } from '../services/previousSearchesService'
import { decodeQueryString } from '../services/urlQueryParamservice'
import LandingView from '../views/LandingView.vue'
import VerifyAppView from '../views/VerifyAppView.vue'
import Widget from '../views/Widget.vue'
import ReferenceView from '../views/ReferenceView.vue'

const loadedLanguages = ['dk', 'en', 'fi', 'no', 'se'] as string[]

export function setMarketPath(lang: string) {
  if (lang === 'sv') {
    lang = 'se'
  } else if (lang === 'da') {
    lang = 'dk'
  }
  if (!loadedLanguages.includes(lang)) {
    lang = 'en'
  }
  document.querySelector('html')?.setAttribute('lang', lang)
  setNormalizedLocale(lang)
  return lang
}

let marketPath = translationInstance.i18next.language.slice(0, 2)
marketPath = setMarketPath(marketPath)

export const routes: RouteRecordRaw[] = [
  {
    path: '/',
    redirect: `/${marketPath}`
  },
  {
    path: '/:lang',
    name: 'landingView',
    component: LandingView,
    props: { language: marketPath }
  },
  {
    path: '/:lang/reference',
    name: 'ReferenceView',
    component: ReferenceView
  },
  {
    path: '/:lang/tracking',
    name: 'Widget',
    component: Widget
  },
  {
    path: '/:lang/verification',
    name: 'VerificationMSg',
    component: VerifyAppView
  },
  {
    path: '/:lang/:pathMatch(.*)*',
    name: 'NotFound',
    redirect: () => {
      return {
        name: 'landingView'
      }
    }
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
